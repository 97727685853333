import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Playground from 'components/Playground';
import Tab from "components/Tab";
import Nav from "components/Nav";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tab"
    }}>{`Tab`}</h1>

    <Playground __position={0} __code={'() => {\n  const [activeTab, setActiveTab] = React.useState(\'1\')\n  const toggle = tab => {\n    if (activeTab !== tab) setActiveTab(tab)\n  }\n  return (\n    <>\n      <Nav tabs>\n        <Nav.Item>\n          <Nav.Link\n            active={activeTab === \'1\'}\n            onClick={() => {\n              toggle(\'1\')\n            }}\n          >\n            Tab 1\n          </Nav.Link>\n        </Nav.Item>\n        <Nav.Item>\n          <Nav.Link\n            active={activeTab === \'2\'}\n            onClick={() => {\n              toggle(\'2\')\n            }}\n          >\n            Tab 2\n          </Nav.Link>\n        </Nav.Item>\n      </Nav>\n      <Tab activeTab={activeTab}>\n        <Tab.Item tabId=\"1\">Tab 1</Tab.Item>\n        <Tab.Item tabId=\"2\">Tab 2</Tab.Item>\n      </Tab>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Tab,
      Nav,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [activeTab, setActiveTab] = React.useState('1');

        const toggle = tab => {
          if (activeTab !== tab) setActiveTab(tab);
        };

        return <>
        <Nav tabs mdxType="Nav">
          <Nav.Item>
            <Nav.Link active={activeTab === '1'} onClick={() => {
                toggle('1');
              }}>
              Tab 1
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link active={activeTab === '2'} onClick={() => {
                toggle('2');
              }}>
              Tab 2
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab activeTab={activeTab} mdxType="Tab">
          <Tab.Item tabId="1">Tab 1</Tab.Item>
          <Tab.Item tabId="2">Tab 2</Tab.Item>
        </Tab>
      </>;
      }}
    </Playground>
    <p>{`### Changes`}</p>
    <ul>
      <li parentName="ul">{`TabContent = Tab`}</li>
      <li parentName="ul">{`TabPane = Tab.Item`}</li>
    </ul>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <p><a href="https://reactstrap.github.io/components/tabs/" target="_blank">Reactstrap Tab</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      